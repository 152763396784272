import * as React from 'react'
const SvgBurger = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 64 64"
    width="1em"
    height="1em"
    {...props}
  >
    <path stroke="#343435" strokeWidth={2} d="M16 32h32M16 20h32M16 44h32" />
  </svg>
)
export default SvgBurger
