import {Link} from "gatsby";
import React from "react";

const ArticleLink = ({ title, content, url }: { title: string, content: string, url: string }) => {
    let shortContent = content.length > 200 ? content.slice(0, 200) + '...' : content;
    return (
        <Link to={url} className="question-link">
            <h2>{title}</h2>
            <p>{shortContent}</p>
        </Link>
    );
};

export default ArticleLink;