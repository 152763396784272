import {FilledContentRelationshipField} from "@prismicio/types"

/** Type definitions for language options used across the project */
export type ILanguage = 'en-us' | 'de-de' | 'es-es' | 'pt-pt' | 'uk' | 'ru'

/**
 * Make sure the language value is correct
 *
 * @param language
 */
export function ensureLanguage(language?: ILanguage | string | null): ILanguage {
  switch (language) {
    case `en-us` || "en_us" || "english" :
      return `en-us`
    case `es-es` || "es_es" :
      return `es-es`
    case `de-de` || "de_de" :
      return `de-de`
    case `pt-pt` || "pt_pt" :
      return `pt-pt`
    case `uk` :
      return `uk`
    case `ru` :
      return `ru`
    default:
      return `en-us`
  }
}

/** Type definitions for a Homepage */
export interface IHomePage {
  /** Prismic previewable id for page */
  _previewable: string

  /** Document type
   * @default "home"
   */
  type: string

  /** Language of homepage
   * @type {ILanguage}
   */
  lang: ILanguage

  /** Unique id of homepage */
  id: string

  /** Contains links to versions of the page in alternate languages */
  alternate_languages: { lang: ILanguage; type: string; uid: string }[]

  /** Main content data of the homepage
   *  Contains arrays of links and top_links
   */
  data: {
    links: { link: IHomePageLink }[]
    top_links: { top_link: IHomePageLink }[]
  }
}

/** Type definitions for a link to an article featured on the homepage */
export interface IHomePageLink {
  document: {
    id: string
    data: {
      title: {
        text: string
      }
      content: {
        text: string
        html: string
      }
    }
    lang: ILanguage
    uid: string
    url: string
  }
}

// Type definitions for a Help Article
export interface IHelpArticle {
  /** Prismic previewable id for page */
  _previewable: string

  /** The uid of the version of the article in it's default language
   *  In this case, the uid of the english version of the post.
   *
   *  This is useful for finding all articles that are also translated versions
   *  as they will all have the same defaultLangUid.
   *
   *  For a post that is in english already, the defaultLangUid will be it's own uid.
   */
  defaultLangUid: string

  /** Title of the post (as plain text string) */
  title: string

  /** Language of article
   * @type {ILanguage}
   */
  lang: ILanguage

  /** Relative url path to the article */
  url: string

  /** Main body content of post (as html string) */
  content: string

  /** Unique id of article */
  id: string

  /** Array containing articles related to the current article */
  related: IRelatedArticle[]
}

/** Type definition for a related article */
export interface IRelatedArticle {
  link: {
    id: string,
    uid: string,
    slug: string,
    // document: {
    //   id: string
    //   data: {
    //     title: { text: string }
    //   }
    //   url: string
    // }
  }
}

/** Type definitions for a translationData
 *
 *  Contains current locale information, and an array of other translated locales
 */
export interface ITranslationData {
  /** Current language */
  lang: ILanguage

  /** Relative URL path to current page */
  url: string

  /** Array of alternate translation data */
  translations: {
    lang: ILanguage
    url: string
  }[]
}

/** Type definiton for an Algolia index */
export interface IAlgoliaIndex {
  name: string
  title: string
}
