import React, {useMemo, useState} from 'react'
import {liteClient as algoliasearch} from 'algoliasearch/lite'
import {Configure, Hits, InstantSearch, SearchBox, useInstantSearch} from 'react-instantsearch'
import {ensureLanguage, IAlgoliaIndex} from 'utils/types'
import {PageHit} from "./SearchResults";
import {BsSearch} from "react-icons/bs";

interface SearchProps {
    language: string,
    size: "large" | "small",
}

export default function Search({language}: SearchProps) {
    const searchIndices: IAlgoliaIndex[] = [
        {name: `Articles`, title: `Articles`},
    ]

    const [searching, setSearching] = useState(false);
    const [focusedHitIndex, setFocusedHitIndex] = useState<number>(0);
    const [query, setQuery] = useState<string>("");

    const searchClient = useMemo(
        () =>
            algoliasearch(
                process.env.GATSBY_ALGOLIA_APP_ID!,
                process.env.GATSBY_ALGOLIA_SEARCH_KEY!,
            ),
        [],
    );

    const handleKeyDown = (event: React.KeyboardEvent) => {
        if (!searching) return;

        if (event.key === 'ArrowDown') {
            event.preventDefault();
            setFocusedHitIndex((prevIndex) => (prevIndex === null ? 0 : prevIndex + 1));
        } else if (event.key === 'ArrowUp') {
            event.preventDefault();
            setFocusedHitIndex((prevIndex) => (prevIndex === null ? 0 : Math.max(prevIndex - 1, 0)));
        } else if (event.key === 'Enter' && focusedHitIndex !== null) {
            event.preventDefault();
            const hitElement = document.querySelectorAll(`.ais-Hits-list li:nth-child(${focusedHitIndex}) div`)[0];
            if (hitElement) {
                hitElement.dispatchEvent(new MouseEvent('click', {bubbles: true}));
            }
        }
    };

    return (
        <div className="search-box">
            <div className="query">
                <InstantSearch
                    searchClient={searchClient}
                    indexName={searchIndices[0].name}
                >
                    <RenderState query={query}/>
                    {searching && <Configure filters={`lang:${ensureLanguage(language)}`} facets={["lang"]}/>}
                    <SearchBox resetIconComponent={undefined}
                               classNames={{submit: "btn primary"}}
                               placeholder={"Search help articles..."}
                               onKeyDown={handleKeyDown}
                               queryHook={(q, hook) => {
                                   setQuery(q);
                                   if (q.length < 3) {
                                       setSearching(false);
                                       return
                                   }
                                   setSearching(true);
                                   hook(q)
                               }}
                               submitIconComponent={() => <BsSearch className="query-button"/>}
                    />
                    {searching && <Hits hitComponent={({hit}) => <PageHit hit={hit} focusIndex={focusedHitIndex}/> }/>}
                </InstantSearch>
            </div>
        </div>
    )
}

function RenderState({query}: {query: string}) {
    const search = useInstantSearch();

    // console.log("--------------------");
    // console.log(search);
    // console.log("RS Status: ", search.status);
    // console.log("RS Results query: ", search.results.query);
    // console.log("RS Box query: ", search.indexRenderState.searchBox?.query);

    // IMPORTANT
    // When the React state updated (i.e., the `useState` hook), the input for the search box gets cleared
    // so here we set it back to a correct value.
    // But make sure we don't do it on each even (i.e., different `status`) because otherwise it makes an infinite loop
    // because the `refine` function triggers a new search and so on. So we update it only when the search is already done.

    if (search.status == "idle" && search.results.query !== query && query.length > 2) {
        // console.log("RS refine");
        search.indexRenderState.searchBox?.refine(query);
    }

    return null;
}