/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from "react"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

type SEOProps = {
  description?: string,
  image?: string,
  lang?: string,
  meta?: any[],
  title: string,
}

const SEO = ({
               description = "",
               image,
               lang = "en",
               meta = [],
               title,
             }: SEOProps) => {
  const { site } = useStaticQuery(
      graphql`
      query {
        site {
          siteMetadata {
            title
            description
          }
        }
      }
    `
  )

  const metaDescription = description || site.siteMetadata.description
  const metaImage = image || "/images/logo.svg"
  const defaultTitle = site.siteMetadata?.title

  return (
      <Helmet
          htmlAttributes={{
            lang,
          }}
          title={title}
          titleTemplate={defaultTitle ? `%s | ${defaultTitle}` : undefined}
          meta={[
            {
              name: `description`,
              content: metaDescription,
            },
            {
              name: `image`,
              content: metaImage,
            },
            {
              property: `og:title`,
              content: title,
            },
            {
              property: `og:description`,
              content: metaDescription,
            },
            {
              property: `og:image`,
              content: metaImage,
            },
            {
              property: `og:type`,
              content: `website`,
            },
            {
              name: `twitter:card`,
              content: `summary_large_image`,
            },
            {
              name: `twitter:title`,
              content: title,
            },
            {
              name: `twitter:description`,
              content: metaDescription,
            },
            {
              name: `twitter:image`,
              content: metaImage,
            },
          ].concat(meta)}
      />
  )
}



export default SEO
