import React, {useState} from 'react'
import getLanguageNameFromCode from 'utils/helpers/getLanguageName'
import {graphql, Link, useStaticQuery} from 'gatsby'
import {IHelpArticle} from 'utils/types'
import {MdLanguage} from "react-icons/md";
import ArticleLink from "./ArticleLink";

interface ViewArticleProps {
    article: IHelpArticle
    otherLanguages: Pick<IHelpArticle, 'lang' | 'url'>[]
}

type ArticleLinkResult = {
    node: {
        id: string,
        url: string,
        lang: string,
        uid: string,
        data: {
            content: {
                text: string
            },
            title: {
                text: string
            }
        }
    }
}

const RelatedArticle = ({uid}: { uid: string }) => {
    let gql = graphql`
        query {
            allPrismicArticle {
              edges {
                node {
                  id,
                  url,
                  lang,
                  uid,
                  data {
                    content {
                      text              
                    },
                    title {
                      text
                    }
                  }
                }        
              }
            }
        }`;
    let data = useStaticQuery(gql);
    let values = data.allPrismicArticle.edges as ArticleLinkResult[];
    let link = values.find(l => l.node.uid === uid);
    if (link === undefined) {
        return null;
    }
    return (
        <ArticleLink
            url={link.node.url}
            content={link.node.data.content.text}
            title={link.node.data.title.text}
            />
    )

}

export default function ViewArticle({article, otherLanguages,}: ViewArticleProps) {
    const [showLanguages, setShowLanguages] = useState(false);

    const toggleLanguageSelector = () => {
        console.log("Toggle languages");
        setShowLanguages(!showLanguages);
    };

    let languageSelector = null;
    let hasOtherLanguages = otherLanguages.length > 0;

    if (hasOtherLanguages && showLanguages) {
        console.log("Show languages", otherLanguages);
       languageSelector = <ul className="language-selector">
            {otherLanguages.map(({lang, url}) => (
                <li key={lang}>
                    <Link to={url}>
                        {getLanguageNameFromCode(lang)}
                    </Link>
                </li>
            ))}
        </ul>;
    }

    // for a some reason it may have links with no uid
    let related = article.related.filter((r) => r.link.uid !== null);
    let relatedCount = related.length;
    let hasRelated = relatedCount > 0;
    let relatedColumns = "three";

    if (relatedCount > 6 || relatedCount == 4) {
        relatedColumns = "four";
    }

    return (
        <div className="article-view">
            <div className={"content"}>

                <div className={"header"}>
                    <h1>{article.title}</h1>
                    <div className="language">
                        <span onClick={toggleLanguageSelector}><MdLanguage/> {getLanguageNameFromCode(article.lang)}</span>
                        {languageSelector}
                    </div>
                </div>

                <div className="html-body" dangerouslySetInnerHTML={{__html: article.content}}></div>
            </div>

            <div className={"meta"}>
                {hasRelated && (
                    <div className={"questions"}>
                        <h2>See also</h2>
                        <div className={`columns-${relatedColumns}`}>
                            {related.map((rel) => (
                                <RelatedArticle uid={rel.link.uid} key={rel.link.uid}/>
                            ))}
                        </div>
                    </div>
                )}
            </div>

        </div>
    )
}
