import React from 'react'
import { IHomePage } from 'utils/types'
import { Link } from 'gatsby'
import Search from "../Search/Search";
import ArticleLink from "../ViewArticle/ArticleLink";

interface HomepageProps {
  homepage: IHomePage
}

export default function Homepage({ homepage }: HomepageProps) {
  return (
      <div className={"homepage"}>
          <div className={"top"}>
          <h1 className={"title"}>Help Center</h1>
          </div>

          <Search language={homepage.lang} size={"large"}/>

          <div className="questions top columns-two">
              {homepage.data.top_links.map(({top_link}) => (
                  <ArticleLink
                      key={top_link.document.id}
                      title={top_link.document.data.title.text}
                      content={top_link.document.data.content.text}
                      url={top_link.document.url}
                  />
              ))}
          </div>

          <div className="questions other columns-three">
              {homepage.data.links.map(({link}) => (
                  <ArticleLink
                      key={link.document.id}
                      title={link.document.data.title.text}
                      content={link.document.data.content.text}
                      url={link.document.url}
                  />
              ))}
          </div>


      </div>
  )
}
