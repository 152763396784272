import { navigate } from 'gatsby'
import { default as React } from 'react'
import {Hit} from "algoliasearch";
import {Highlight, Snippet, BasicDoc} from "react-instantsearch";

interface PageHitProps {
  hit: Hit<BasicDoc>;
  focusIndex: number | null;
}

export const PageHit = ({ hit, focusIndex }: PageHitProps) => {
  const focused = focusIndex === hit.__position;
  return (
      <div
        onClick={() => navigate(hit.url)}
        className={`page-hit ${focused ? 'focused' : ''}`}
      >
        <h3 className="title">
          <Highlight attribute="title" hit={hit} highlightedTagName="mark" />
        </h3>
        <p className="content">
          <Snippet attribute="content" hit={hit} highlightedTagName="mark" />
        </p>
      </div>
  )
}