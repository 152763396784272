module.exports = [{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-prismic-previews@6.0.2_@types+react@18.3.4_gatsby-plugin-image@3.13.1_gatsby-so_caj6dacpiylvuhwkqagay277z4/node_modules/gatsby-plugin-prismic-previews/gatsby-browser.js'),
      options: {"plugins":[],"repositoryName":"emerald-help","accessToken":"MC5ZbjFCOWhNQUFDRUF1T2Zs.Xu-_ve-_ve-_vWInK--_ve-_vXxUYQl777-977-9OVAxZ0vvv73vv70777-977-977-977-9RlPvv71R"},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-manifest@5.13.1_gatsby@5.13.7_graphql@16.9.0/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"emerald-help","short_name":"help","start_url":"/","background_color":"#1d1d1e","theme_color":"#32d486","display":"minimal-ui","icon":"src/images/logo.svg","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"f01b0e3f0ee9704ddcbdcc4db9bd1688"},
    },{
      plugin: require('../gatsby-browser.tsx'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/.pnpm/gatsby@5.13.7_babel-eslint@10.1.0_react-dom@18.3.1_react@18.3.1_typescript@5.5.4/node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
