import {AnalyticsConfig} from "@emeraldpay/internal-ui";
import {graphql, useStaticQuery} from "gatsby";

export function analyticsConfig(): AnalyticsConfig {
    const {site} = useStaticQuery(
        graphql`query { site { siteMetadata { ga } } }`
    );
    return {
        google: site.siteMetadata.ga
    }
}