import * as React from 'react'
const SvgLogoGray = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 320 480"
    width="1em"
    height="1em"
    {...props}
  >
    <path
      fill="#343435"
      d="m0 320 160 160 160-160L0 160zM160 0 0 80l320 240V80z"
    />
  </svg>
)
export default SvgLogoGray
