import type {RepositoryConfig} from "gatsby-plugin-prismic-previews/src/types";

/**
 * This file contains configuration for `gatsby-plugin-prismic-previews` to
 * support preview sessions from Prismic with drafts and unpublished documents.
 *
 * @see https://prismic.io/docs/technologies/previews-gatsby
 */
const { prismicRepo } = require('../../../prismic-configuration')
import { linkResolver } from './linkResolver'

import ArticleTemplate from 'templates/article'
import HomepageTemplate from 'templates/homepage'

/**
 * Prismic preview configuration for each repository in your app. This set of
 * configuration objects will be used with the `PrismicPreviewProvider`
 * higher order component.
 *
 * If your app needs to support multiple Prismic repositories, add each of
 * their own configuration objects here as additional elements.
 *
 */
export const repositoryConfigs: RepositoryConfig[] = [
  {
    repositoryName: prismicRepo,
    linkResolver,
    componentResolver: {
      article: ArticleTemplate,
      home: HomepageTemplate,
    },
  },
]
