import * as React from 'react'
import {useStaticQuery, graphql} from 'gatsby'
import {Helmet} from 'react-helmet'
import {ITranslationData} from 'utils/types'
import {WebPage} from "@emeraldpay/internal-ui";
import {analyticsConfig} from "../commons/analytics";
import Search from "../modules/Search/Search";
import { IoMdMail } from 'react-icons/io'

import '../styles/global.scss'

interface LayoutProps {
    children: React.ReactNode
    translationData: ITranslationData,
    className?: string
}

interface NavigationProps {
    lang: string
}

const Navigation = ({lang}: NavigationProps) => {
    return (
        <div className={"nav"}>
            <a href={"/support"}><IoMdMail /> Support</a>
            <Search language={lang} size={"small"}/>
        </div>
    )
}

export const Layout = ({children, translationData, className}: LayoutProps) => {
    const queryData = useStaticQuery(graphql`
        query SiteQuery {
          site {
            siteMetadata {
              title
              description
            }
          }
        }
      `);

    const navigation = <Navigation lang={translationData.lang}/>;


    return (
        <>
            <Helmet>
                <meta charSet="utf-8"/>
                <title>{queryData.site.siteMetadata.title}</title>
                <meta
                    name="description"
                    content={queryData.site.siteMetadata.description}
                />
                <meta name="viewport" content="width=device-width, initial-scale=1.0"/>
            </Helmet>
            <WebPage className={className || ""} gdpr={analyticsConfig()} header={{links: navigation, productName: "help"}}>
                {children}
            </WebPage>
        </>
    )
}
